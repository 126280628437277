import { createSlice,createAsyncThunk,PayloadAction } from '@reduxjs/toolkit'
import { getPerfil,ediPerfil} from '../../services';
import {IUser, IUserState} from '../types/IUser';
import history from "../../helpers/history";
import jwt_decode from "jwt-decode";
import {clearIdToken} from "../../services/auth";
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;



const initialState:IUserState = {
    status:"idle",
    isAuthenticated: false,
    user: {
        rol: "",
        token: "",
        email: "",
        inicioSesion: "",
        nombre: "",
    },
}

export const mostrarPerfil = createAsyncThunk(
    'mostrarPerfil', async (user:IUser|undefined, thunkAPI ) => {

        if(user) {
            let data =user as IUser;
            // if(data?.firma) {
            //     await imageUrlToBase64(data?.firma).then(base64=>{
            //         data = {
            //             ...data,
            //             firma: base64 as string
            //         }
            //     });
            // }


            return data
        };

        try {
            const response = await getPerfil()
            let data = response.data as IUser;
            // if(data?.firma) {
            //
            //     await imageUrlToBase64(data?.firma).then(base64=>{
            //         data = {
            //             ...data,
            //             firma: base64 as string
            //         }
            //     }).catch(r=>{
            //         alert(JSON.stringify(r))
            //
            //     });
            // }


            return data;

        } catch (err) {
            // @ts-ignore
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)
export const editarPerfil = createAsyncThunk(
    'editarPerfil', async (user:IUser ) => {

        let response = await ediPerfil(user);
        if(response.status === 200 && (window as any).ReactNativeWebView){
            let ms = {
                action: "setCurrentUser",
                payload:  {
                 ...response.data
                }
            };

            (window as any).ReactNativeWebView.postMessage(JSON.stringify(ms));

        }
       // if (response.status === 200) thunkAPI.dispatch(Notifications.success({ title: 'Guardado con éxito', message: '', autoDismiss: 5, }));

        return response.data;
    }
)


const slice = createSlice({
    name: 'authReducer',
    initialState,
    reducers: {
        setCurrentUserInfo(state:IUserState, action: PayloadAction<string>) {
            const decoded = jwt_decode(action.payload);
            state.user = decoded as IUser;
            // console.log(action.payload)

        },
        setCurrentUser(state,action: PayloadAction<IUser>) {
            state.user = {...state.user,...action.payload};
        },
        userLogout() {
            // state.isAuthenticated = false;
            // state.user = initialState.user;


            clearIdToken()

            if((window as any).ReactNativeWebView ){
                (window as any).ReactNativeWebView.postMessage(JSON.stringify({action:"logOut",payload:{}}))

            }else if(iOS){
                (window as any).location = "/login"

            }else{
                history.replace("/login");

            }




        },
    },
    extraReducers: builder => {
        //---mostrarPerfil
        builder.addCase(mostrarPerfil.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(mostrarPerfil.fulfilled, (state, action) => {
            state.status = "idle"
            state.isAuthenticated = true;
            state.user = action.payload;
        })
        builder.addCase(mostrarPerfil.rejected, (state, action) => {
            state.status = "idle"
        })

        //---editarPerfil
        builder.addCase(editarPerfil.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(editarPerfil.fulfilled, (state, action) => {
            state.status = "idle"
            state.user = {...state.user,...action.payload}
        })
        builder.addCase(editarPerfil.rejected, (state, action) => {
            state.status = "idle"
        })
    }

});



export const {userLogout,setCurrentUser,setCurrentUserInfo} = slice.actions;
export const authReducer = slice.reducer;
