import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {getPaises} from '../../services';
import {AppState} from "./rootReducer";
import {IPais, IPaisesState} from "../types/IPais";

const initialState: IPaisesState= {
    status: "idle",
    list:[]
}

export const mostrarPaises = createAsyncThunk(
    'mostrarPaises', async () => {
            const response = await getPaises()
            return {data: response.data as IPais[]}
    }
)
export const mostrarPaisesSinoEsta = createAsyncThunk(
    'mostrarPaisesSinoEsta', async (_,thunkAPI) => {
        let paises = (thunkAPI.getState() as AppState)?.paises;
        if (paises?.list?.length || paises.status === "loading") return false;

        return thunkAPI.dispatch(mostrarPaises());

    }
)

const slice = createSlice({
    name: 'mostrarPaises',
    initialState,
    reducers: {
        setPaises(state,action: PayloadAction<IPais[]>) {
            state.list = action.payload;
        },
    },
    extraReducers: builder => {
        //--mostrarPaises
        builder.addCase(mostrarPaises.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPaises.fulfilled, (state,action ) => {
            state.status = "idle";
            state.list = action.payload.data;
        })
        builder.addCase(mostrarPaises.rejected, (state ) => {
            state.status = "idle";
        })
    }

});



export const {setPaises} = slice.actions;

export default slice.reducer;
