import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    addAppUser,
    addAppUserNota,
    addAppUserPago,
    delAppUserNota,
    delAppUserPago,
    getAppUsers,
    getAppUsersById,
    putAppUser
} from '../../services';
import {AppState} from "./rootReducer";
import {IAppUser, IAppUserFilter, IAppUserNota, IAppUserRes, IAppUserState} from "../types/IAppUser";
import {IRegisterUser} from "../types/IUser";

const initialState: IAppUserState = {
    status: "idle",
}

export const mostrarAppUsers = createAsyncThunk(
    'appUsers/mostrar', async (params:IAppUserFilter|undefined, thunkAPI ) => {
        const {appUsers} = thunkAPI.getState() as AppState;
        let paramsFilter = params || appUsers.filtered;

        const response = await getAppUsers(paramsFilter)
        return {data: response.data as IAppUserRes, params: response.config.params}
    }
)
export const editarAppUser = createAsyncThunk(
    'appUser/editar', async (params: IAppUser, thunkAPI) => {
        const response = await putAppUser(params);

        return {data: response.data};
    }
);
export const agregarAppUser = createAsyncThunk(
    'appUser/agregar', async (params: IRegisterUser, thunkAPI) => {
        const response = await addAppUser(params);

        return {data: response.data};
    }
);


export const mostrarAppUserById = createAsyncThunk(
    'mostrarAppUserById', async (id:number,thunkAPI) => {
        // let byId = (thunkAPI.getState() as AppState)?.prestamos?.list?.items?.find(c=>c.id === id);
        // if(byId){
        //     return {data: byId}
        // }

        const response = await getAppUsersById(id);
        return {data: response.data as IAppUser}
    }
)
export const agregarAppUserPago = createAsyncThunk(
    'agregarAppUserPago', async (params:any) => {
        const response = await addAppUserPago(params);
        return {data: response.data};
    }
);
export const eliminarAppUserPago = createAsyncThunk(
    'AppUser/eliminarPago', async (params:{id:number, companiaId:number}) => {
        delAppUserPago(params);
        return params.id;
    }
);

export const agregarAppUserNota = createAsyncThunk(
    'agregarAppUserNota', async (params:IAppUserNota) => {
        const response = await addAppUserNota(params);
        // if (response.status === 200 || response.status === 204) {
        //     historyHelper.back()
        // };

        return {data: response.data};
    }
);
export const eliminarAppUserNota = createAsyncThunk(
    'AppUser/eliminarNota', async (params:{companyId:number, id:number}) => {
        delAppUserNota(params.id, params.companyId);
        return params.id;
    }
);

const slice = createSlice({
    name: 'appUsers',
    initialState,
    reducers: {
        updateAppUser(state:IAppUserState, action: PayloadAction<IAppUser>) {
            let data = action.payload;
            let list = {...state?.list} as IAppUserRes;
            if(list?.items) {
                list.items = list.items.filter(e => e.id !== data.id);
                list.items.unshift(data)
            }else{
                list.items = [data]
            }

            state.list = list

        },
    },
    extraReducers: builder => {
        builder.addCase(mostrarAppUsers.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAppUsers.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarAppUsers.rejected, (state ) => {
            state.status = "idle";
        })

        //mostrarAppUserById
        builder.addCase(mostrarAppUserById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAppUserById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data;
        })
        builder.addCase(mostrarAppUserById.rejected, (state ) => {
            state.status = "idle";
        })
        //--agregarAppUser
        builder.addCase(agregarAppUser.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarAppUser.fulfilled, (state,action ) => {
            state.status = "idle";
            const { data } = action.payload;
            if(state.list){
                state.list.items.unshift(data);
                ++state.list.pageSize
                ++state.list.totalCount
            }
        })
        builder.addCase(agregarAppUser.rejected, (state ) => {
            state.status = "idle";
        })
        //--editarAppUser
        builder.addCase(editarAppUser.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(editarAppUser.fulfilled, (state,action ) => {
            state.status = "idle";
            const { data } = action.payload;
            if(state.list){
                state.list.items = state.list?.items.filter(e => e.id !== data.id);
                state.list.items.unshift(data);
            }
            if(state.byId){
                state.byId = data
            }
        })
        builder.addCase(editarAppUser.rejected, (state ) => {
            state.status = "idle";
        })



        //--agregarPago
        builder.addCase(agregarAppUserPago.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarAppUserPago.fulfilled, (state,action ) => {
            state.status = "idle";
            const { data } = action.payload;
            const pagos = [...state.byId?.pagos ?? []];
            pagos.push(data);

            state.byId = {...state.byId, pagos}


        })
        builder.addCase(agregarAppUserPago.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarPago
        builder.addCase(eliminarAppUserPago.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarAppUserPago.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            const pagos = state.byId?.pagos?.map(pago=>{
                if(pago.id === id){
                    return {...pago, fechaAnulacion: new Date().toISOString()}
                }
                return pago
            })

            state.byId = {...state.byId, pagos}

        })
        builder.addCase(eliminarAppUserPago.rejected, (state ) => {
            state.status = "idle";
        })
        
        
        //--agregarNota
        builder.addCase(agregarAppUserNota.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarAppUserNota.fulfilled, (state,action ) => {
            state.status = "idle";
            const { data } = action.payload;
            const notas = [...state.byId?.notas ?? []];
            notas.push(data);

            state.byId = {...state.byId, notas}


        })
        builder.addCase(agregarAppUserNota.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarNota
        builder.addCase(eliminarAppUserNota.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarAppUserNota.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            const notas = [...state.byId?.notas?.filter(n=>n.id !== id) ?? []];

            state.byId = {...state.byId, notas}

        })
        builder.addCase(eliminarAppUserNota.rejected, (state ) => {
            state.status = "idle";
        })
    }
});

export const { updateAppUser} = slice.actions;
export default slice.reducer;
