import React from 'react';
import {createRoot} from "react-dom/client";

import {Provider} from 'react-redux'
import {store} from './redux/store';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';
//auth -----------------------
import {mostrarPerfil} from './redux/reducers/authReducer';
import Strings from "./assets/strings";
import {getLSLang} from "./helpers";
import {PromptToInstallProvider} from "./PromptToInstall";
import {getIdToken} from "./services/auth";
//end auth -----------------------






//import * as serviceWorker from './serviceWorker';


if(getIdToken()) {
    store.dispatch(mostrarPerfil()); // to get the color en currency
}
//setear idioma
let lang = getLSLang("l");
Strings.setLanguage(lang);

if (!Object.entries) {
    Object.entries = function( obj:any ){
        let ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };
}


const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <PromptToInstallProvider>
        <Provider store={store}>
         {/*<React.StrictMode>*/}
            <App />
        {/*</React.StrictMode>*/}
        </Provider>
    </PromptToInstallProvider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


//registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();



// let installprompt:any = null;
// window.addEventListener('beforeinstallprompt',  (e) => {
//     e.preventDefault();
//     installprompt = e;
//     console.log("de", installprompt)
//     store.dispatch(setInstallBannerEvent(e));
//     console.log(installprompt)
// });
// console.log(installprompt)

