import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    aprobarSolicitud as aprobar,
    rechazarSolicitud as rechazar,
    getSolicitudes
} from '../../services';
import {ISolicitud, ISolicitudFilter, ISolicitudRes, ISolicitudState} from "../types/ISolicitud";
import historyHelper from "../../helpers/history";
import {AppState} from "./rootReducer";

const initialState: ISolicitudState = {
    status: "idle"
}

export const mostrarSolicitudes = createAsyncThunk(
    'mostrarSolicitudes', async (params:ISolicitudFilter|undefined, thunkAPI ) => {
        const {solicitudes} = thunkAPI.getState() as AppState;
        let paramsFilter = params || solicitudes.filtered;

        const response = await getSolicitudes(paramsFilter)
        return {data: response.data as ISolicitudRes, params: response.config.params}
    }
)
export const mostrarSolicitudById = createAsyncThunk(
    'mostrarSolicitudById', async (id:number,thunkAPI) => {
        // let byId = (thunkAPI.getState() as AppState)?.solicitudes?.list?.items?.find(c=>c.id === id);
        // if(byId){
        //     return {data: byId}
        // }

        const response = await getSolicitudes(id);
        return {data: response.data as ISolicitud}
    }
)

export const aprobarSolicitud = createAsyncThunk(
    'Solicitudes/aprobar', async (solicitud: ISolicitud, thunkAPI) => {
        const response = await aprobar(solicitud);

        if (response.status === 200 || response.status === 204) {
            thunkAPI.dispatch(mostrarSolicitudes())
            historyHelper.push("/prestamos")
        };
        return {data: response.data};
    }
);
export const rechazarSolicitud = createAsyncThunk(
    'Solicitudes/rechazar', async (id: number, thunkAPI) => {
        const response = await rechazar(id);

        if (response.status === 200 || response.status === 204) {
            thunkAPI.dispatch(mostrarSolicitudes())
            historyHelper.push("/solicitudes")
        };
        return {data: response.data};
    }
);


const slice = createSlice({
    name: 'mostrarSolicitudes',
    initialState,
    reducers: {},
    extraReducers: builder => {
        //--mostrarSolicitudes
        builder.addCase(mostrarSolicitudes.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarSolicitudes.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarSolicitudes.rejected, (state ) => {
            state.status = "idle";
        })

        //--mostrarSolicitudById
        builder.addCase(mostrarSolicitudById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarSolicitudById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data;
        })
        builder.addCase(mostrarSolicitudById.rejected, (state ) => {
            state.status = "idle";
        })

        //--aprobarSolicitud
        builder.addCase(aprobarSolicitud.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(aprobarSolicitud.fulfilled, (state,action ) => {
          //  const {data} = action.payload;
            state.status = "idle";
        //    state.byId = data;
        })
        builder.addCase(aprobarSolicitud.rejected, (state ) => {
            state.status = "idle";
        })
        //--rechazarSolicitud
        builder.addCase(rechazarSolicitud.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(rechazarSolicitud.fulfilled, (state,action ) => {
          //  const {data} = action.payload;
            state.status = "idle";
          //  state.byId = data;
        })
        builder.addCase(rechazarSolicitud.rejected, (state ) => {
            state.status = "idle";
        })

    }
});

export default slice.reducer;
