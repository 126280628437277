import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getUsosMultiples, addEditUsosMultiple, delUsosMultiple} from '../../services';
import {IUsoMultipleState, IUsoMultipleRes, IUsoMultiple} from "../types/IUsosMultiples";
import {AppState} from "./rootReducer";
const initialState: IUsoMultipleState = {
    status: "idle",
    list: {}
}

export const mostrarUsosMultiples = createAsyncThunk(
    'mostrarUsosMultiples', async (params:{name?:string, tipo?:number}|undefined ) => {
        const response = await getUsosMultiples(params)
        return {data: response.data as IUsoMultiple[], params: response.config.params}
    }
)

export const mostrarUsosMultiplesSinoEsta = createAsyncThunk(
    'mostrarUsosMultiplesSinoEsta', async (_, thunkAPI ) => {

        let usosMultiples = (thunkAPI.getState() as AppState)?.usosMultiples;
        if (Object.keys(usosMultiples?.list??{}).length || usosMultiples.status === "loading") return false;

        return thunkAPI.dispatch(mostrarUsosMultiples(undefined));

    }
)


export const agregarEditarUsoMultiple = createAsyncThunk(
    'usoMultiples/agregarEditar', async (usoMultiple: IUsoMultiple) => {
        const response = await addEditUsosMultiple(usoMultiple);
    //    if (response.status === 200) historyHelper.back();
        return {data: response.data, edit:!!usoMultiple.id};
    }
);
export const eliminarUsosMultiples = createAsyncThunk(
    'usoMultiples/eliminar', async (usoMultiple: IUsoMultiple) => {
        await delUsosMultiple(usoMultiple.id!);
        return usoMultiple;
    }
);


const slice = createSlice({
    name: 'mostrarUsosMultiples',
    initialState,
    reducers: {
        setUsosMultiples: (state,{payload}) => {
            state.list = payload
        }
    },
    extraReducers: builder => {
        //---mostrarUsosMultiples
        builder.addCase(mostrarUsosMultiples.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarUsosMultiples.fulfilled, (state,action ) => {
            state.status = "idle";

            const { data } = action.payload
            let list:IUsoMultipleRes = {};
            if (data.length) {
                let tipo = new Set(data.map(g=>g.tipo))
                tipo.forEach((g)=>{
                    list[g!] = data.filter(item=>item.tipo === g)
                })
            }
            state.list = list;
        })
        builder.addCase(mostrarUsosMultiples.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarUsoMultiple
        builder.addCase(agregarEditarUsoMultiple.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarUsoMultiple.fulfilled, (state,action ) => {

            const { data } = action.payload;
            let list:any = {...state.list};
            let listFiltered = (state.list as any)[data.tipo]?.filter((e:IUsoMultiple) => e.id !== data.id) ?? [];
            listFiltered.unshift(data)
            list[data.tipo] = listFiltered;


            state.status = "idle";
            state.list = list;

        })
        builder.addCase(agregarEditarUsoMultiple.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarUsoMultipleCategoria
        builder.addCase(eliminarUsosMultiples.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarUsosMultiples.fulfilled, (state,action ) => {
            const { id,tipo } = action.payload;
            let list:any = {...state.list};

            if(tipo){
                let listFiltered = (state.list as any)[tipo]?.filter((e:IUsoMultiple) => e.id !== id) ?? [];
                list[tipo] = listFiltered;
                state.list = list;
            }
            state.status = "idle";


        })
        builder.addCase(eliminarUsosMultiples.rejected, (state ) => {
            state.status = "idle";
        })
    }

});

export const {setUsosMultiples} = slice.actions;
export default slice.reducer;
