import { createBrowserHistory } from "history";

const historyHelper = createBrowserHistory()

// export const getState = ()=>{
//     const { location:{state} } = historyHelper;
//     if(state && Object.keys(state).length) return state; else return null;
// }
// export const pushState = (obj)=>{
//     const { location:{pathname},push } = historyHelper;
//     return push(pathname,obj)
// }

export default historyHelper;