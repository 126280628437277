import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addEditCartera, delCartera, getCarteras} from '../../services';
import {ICartera, ICarteraState} from "../types/ICartera";
import {AppState} from "./rootReducer";
import historyHelper from "../../helpers/history";

const initialState: ICarteraState= {
    status: "idle",
    list:[]
}

export const mostrarCarteras = createAsyncThunk(
    'mostrarCarteras', async (filter:any = {}) => {
        const response = await getCarteras(filter)
        return {data: response.data as ICartera[]}
    }
)

export const mostrarCarteraById = createAsyncThunk(
    'mostrarCarteraById', async (id:number) => {
        // let byId = (thunkAPI.getState() as AppState)?.carteras?.list?.find(c=>c.id === id);
        // if(byId){
        //     return {data: byId}
        // }

        const response = await getCarteras(id);
        return {data: response.data as ICartera}
    }
)
export const mostrarCarterasSinoEsta = createAsyncThunk(
    'mostrarCarterasSinoEsta', async (_,thunkAPI) => {
        let carteras = (thunkAPI.getState() as AppState)?.carteras;
        if (carteras?.list?.length || carteras.status === "loading") return false;

        return thunkAPI.dispatch(mostrarCarteras(undefined));

    }
)


export const agregarEditarCartera = createAsyncThunk(
    'Cartera/agregarEditar', async (cartera: ICartera) => {
        const response = await addEditCartera(cartera);

         if (response.status === 200 || response.status === 204) historyHelper.back();

        return {data: response.data, edit:!!cartera.id};

    }
);
export const eliminarCartera = createAsyncThunk(
    'cartera/eliminar', async (id: number) => {
        let response = await delCartera(id);
        if (response.status === 200 || response.status === 204) historyHelper.replace("/carteras");

        return id;
    }
);


const slice = createSlice({
    name: 'mostrarCarteras',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        //--mostrarCarteras
        builder.addCase(mostrarCarteras.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarCarteras.fulfilled, (state,action ) => {
            state.status = "idle";
            state.list = action.payload.data;
        })
        builder.addCase(mostrarCarteras.rejected, (state ) => {
            state.status = "idle";
        })
        //--mostrarCarteraById
        builder.addCase(mostrarCarteraById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarCarteraById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data;
        })
        builder.addCase(mostrarCarteraById.rejected, (state ) => {
            state.status = "idle";
        })
        //--agregarEditarCartera
        builder.addCase(agregarEditarCartera.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarCartera.fulfilled, (state,action ) => {
            const {data} = action.payload
            state.status = "idle";

            if(state.list){
                state.list = state.list?.filter(e => e.id !== action.payload.data.id);
                state.list.unshift(action.payload.data);
            }
            if(state.byId?.id === data.id){
                state.byId = {...state.byId, ...data};
            }
        })
        builder.addCase(agregarEditarCartera.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarCartera
        builder.addCase(eliminarCartera.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarCartera.fulfilled, (state,action ) => {
            state.status = "idle";

            const id = action.payload;
            if(state.list){
                state.list = state.list?.filter(e => e.id !== id)
            }
            state.byId = undefined;
        })
        builder.addCase(eliminarCartera.rejected, (state ) => {
            state.status = "idle";
        })
    }

});




export default slice.reducer;
