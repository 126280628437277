import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {readNotificacion, delNotificacion, getNotificaciones} from '../../services';
import {INotificacion, INotificacionState} from "../types/INotificacion";
import {setIndicadoresMenu} from "./dashBoardReducers";

const initialState: INotificacionState= {
    status: "idle",
    list:[]
}

export const mostrarNotificaciones = createAsyncThunk(
    'mostrarNotificaciones', async (verLeidas?:boolean) => {
        const response = await getNotificaciones(verLeidas??false)
        return {data: response.data as INotificacion[]}
    }
)

export const marcarNotLeida = createAsyncThunk(
    'marcarNotLeida', async (id: number, thunkAPI) => {
        let response = await readNotificacion(id);

        if (response.status === 200 || response.status === 204) {
            thunkAPI.dispatch(setIndicadoresMenu({notificaciones: -1}))
        }

        return id;

    }
);
export const eliminarNotificacion = createAsyncThunk(
    'notificacion/eliminar', async (id: number) => {
        await delNotificacion(id);
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarNotificaciones',
    initialState,
    reducers: {
        addNewNotification(state,action: PayloadAction<INotificacion>,) {
            state.list = [...(state.list ?? []), action.payload];
        },
    },
    extraReducers: builder => {
        //--mostrarNotificaciones
        builder.addCase(mostrarNotificaciones.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarNotificaciones.fulfilled, (state,action ) => {
            state.status = "idle";
            state.list = action.payload.data;
        })
        builder.addCase(mostrarNotificaciones.rejected, (state ) => {
            state.status = "idle";
        })
        //--marcarNotLeida
        builder.addCase(marcarNotLeida.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(marcarNotLeida.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;

            if(state.list){
                state.list = state.list?.map(item => {
                    if(item.id === id){
                        return {...item, leida: true}
                    }
                    return item
                })
            }

        })
        builder.addCase(marcarNotLeida.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarNotificacion
        builder.addCase(eliminarNotificacion.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarNotificacion.fulfilled, (state,action ) => {
            state.status = "idle";

            const id = action.payload;
            if(state.list){
                state.list = state.list?.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarNotificacion.rejected, (state ) => {
            state.status = "idle";
        })
    }

});


export const {addNewNotification} = slice.actions;

export default slice.reducer;
