import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {editCompanias, getCompanias} from '../../services';
import {ICompania, ICompaniaState} from "../types/ICompania";
import {AppState} from "./rootReducer";
import {ID_TOKEN_KEY} from "../../services/auth";
import {imageUrlToBase64} from "../../helpers";
const initialState: ICompaniaState = {
    status: "idle",
    data: {},
}

export const mostrarCompanias = createAsyncThunk(
    'mostrarCompanias', async () => {
        const response = await getCompanias()
        let data = response.data as ICompania;

        if(data?.logo) {
            await imageUrlToBase64(data?.logo).then(base64=>{
                data = {
                    ...data,
                    logo: base64 as string
                }
            });

        }


        return {data}
    }
)
export const mostrarCompaniaDatosSinoEsta = createAsyncThunk(
    'mostrarCompaniaDatosSinoEsta', async (_,thunkAPI) => {
        let companias = (thunkAPI.getState() as AppState)?.companias;
        if (Object.keys(companias?.data??{}).length || companias.status === "loading") return false;


        return thunkAPI.dispatch(mostrarCompanias());

    }
)
export const editarCompania = createAsyncThunk(
    'compania/agregarEditar', async (compania: ICompania) => {
        const response = await editCompanias(compania);
        //    if (response.status === 200) historyHelper.back();
        return {data: response.data};
    }
);



const slice = createSlice({
    name: 'mostrarCompanias',
    initialState,
    reducers: {
        setCompania(state,action: PayloadAction<ICompania>) {
            state.data = {...state.data,...action.payload};
        },
    },
    extraReducers: builder => {
        //---mostrarCompanias
        builder.addCase(mostrarCompanias.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarCompanias.fulfilled, (state,action ) => {
            state.status = "idle";
            const { data } = action.payload


            state.data = data;


        })
        builder.addCase(mostrarCompanias.rejected, (state ) => {
            state.status = "idle";
        })

        //--editarCompania
        builder.addCase(editarCompania.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(editarCompania.fulfilled, (state,action ) => {
            const { data } = action.payload;

            localStorage.setItem(ID_TOKEN_KEY, data.token);

            state.status = "idle";
            state.data = data;
        })
        builder.addCase(editarCompania.rejected, (state ) => {
            state.status = "idle";
        })

    }

});



export const {setCompania} = slice.actions;
export default slice.reducer;
