import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getAgentes, addEditAgente, getAgenteById, delAgente,editPWAgente,lockToggleAgente} from '../../services';
import historyHelper from "../../helpers/history";
import {AppState} from "./rootReducer";
import {IAppState} from "../types/Icommon";
import {IUserAgente} from "../types/IUser";

interface IState extends IAppState{
    list: IUserAgente[]
    byId?:IUserAgente
}
const initialState: IState = {
    status: "idle",
    list:[],
    byId:undefined
}

export const mostrarAgentes = createAsyncThunk(
    'mostrarAgentes',
    async (params:{name:string}|undefined, thunkAPI ) => {
        const {agentes} = thunkAPI.getState() as AppState;
        let paramsFilter = params || agentes.filtered;

        const response = await getAgentes(paramsFilter)
        return {data: response.data as IUserAgente[], params: response.config.params}
    }
)
export const mostrarAgentesSinoEsta = createAsyncThunk(
    'mostrarAgentesSinoEsta', async (_,thunkAPI) => {
        let agentes = (thunkAPI.getState() as AppState)?.agentes;
        if (agentes?.list?.length || agentes.status === "loading") return false;

        return thunkAPI.dispatch(mostrarAgentes(undefined));

    }
)
export const mostrarAgenteById = createAsyncThunk(
    'mostrarAgenteById',
    async (id:number) => {
        const response = await getAgenteById(id)
        return response.data as IUserAgente
    }
)

export const agregarEditarAgente = createAsyncThunk(
    'Agentes/agregarEditar',
    async (Agente: IUserAgente) => {
        const response = await addEditAgente(Agente);
        if (response.status === 200 || response.status === 204) historyHelper.back();

        return {data: response.data, edit:!!Agente.id};

    }
);

export const eliminarAgente = createAsyncThunk(
    'agentes/eliminar',
    async (id: number) => {
        await delAgente(id);
        return id;
    }
);

export const toggleLock = createAsyncThunk(
    'agentes/toggleLock',
    async (id: number) => {
        let response = await lockToggleAgente(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();



        return id;
    }
);

export const editarContrasena = createAsyncThunk(
    'agentes/editarContrasena',
    async (params: any) => {
        let response = await editPWAgente(params);
        if (response.status === 200 || response.status === 204) historyHelper.back();

        return params;
    }
);
const slice = createSlice({
    name: 'mostrarAgentes',
    initialState,
    reducers: {
        // mostrarBancas(state: IBancaState, {payload: key}: PayloadAction<string>) {
        //     state.isFetching = true;
        // }
    },
    extraReducers: builder => {
        //---mostrarAgentes
        builder.addCase(mostrarAgentes.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAgentes.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarAgentes.rejected, (state ) => {
            state.status = "idle";
        })

        //---agregarEditarAgente
        builder.addCase(agregarEditarAgente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarAgente.fulfilled, (state,action ) => {
            state.status = "idle";

            if(state.list){
                state.list = state.list?.filter(e => e.id !== action.payload.data.id);
                state.list.unshift(action.payload.data);
                // if(!action.payload.edit){
                //     ++state.list.pageSize
                //     ++state.list.totalRecordCount
                // }
            }
        })
        builder.addCase(agregarEditarAgente.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarAgenteById
        builder.addCase(mostrarAgenteById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAgenteById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarAgenteById.rejected, (state ) => {
            state.status = "idle";
        })

        //---eliminarAgente
        builder.addCase(eliminarAgente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarAgente.fulfilled, (state,action ) => {
            state.status = "idle";

            const id = action.payload;
            if(state.list){
                state.list = state.list?.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarAgente.rejected, (state ) => {
            state.status = "idle";
        })

        //---toggleLock
        builder.addCase(toggleLock.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(toggleLock.fulfilled, (state,action ) => {
            const id = action.payload;
            if(!!state.list){
                state.list = state.list.map(r=>{
                    if(r.id === id){
                        return {
                            ...r,
                            inactivo: !r.inactivo
                        }

                    }
                    return r;
                })
            }
            state.status = "idle";
        })
        builder.addCase(toggleLock.rejected, (state ) => {
            state.status = "idle";
        })

        //---editarContrasena
        builder.addCase(editarContrasena.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(editarContrasena.fulfilled, (state,action ) => {
            state.status = "idle";
        })
        builder.addCase(editarContrasena.rejected, (state ) => {
            state.status = "idle";
        })
    }

});


export default slice.reducer;
