const errorToNotifObj = (obj)=>{
    let notifObj = { title: obj.Title??"Error", message: obj.message??"", autoDismiss: 5};

    // if(Object.keys(obj.errors ?? {}).length){
    //     Object.keys(obj.errors).forEach(prop=>{
    //         notifObj.message += prop+": "+obj.errors[prop][0]
    //     })
    // }
    if(obj.Errors?.length){
        obj.Errors.forEach(item=>{
            notifObj.message += item.Value+"\n"
        })
    }else if(typeof obj.Detail === "string"){
        notifObj.message = obj.Detail;

    }
    if(obj.status === 404) return {...notifObj,title:"Error 404"};

    if(typeof obj === "string"){
        notifObj.message = obj;
    }
    return notifObj

}
export default errorToNotifObj

//
// const errorToNotifObj = (obj)=>{
//     let notifObj = { title: '', message: '', autoDismiss: 5, };
//
//     if(obj && obj.status === 404) return {...notifObj,title:"Error "+ obj.status};
//
//     if(obj === "string"){
//         notifObj.title = obj;
//     }else if (obj.data) {
//         let data = obj.data;
//
//         if (typeof data === "string"){
//             notifObj.title = data;
//         }else if (typeof data === "object"){
//             let { title,value} = data;
//             if (title || value){
//                 notifObj.title = title || value;
//             }else{
//                 notifObj.title = "Error!";
//             }
//         }
//     }
//     return notifObj
// }
// export default errorToNotifObj
