import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addEditIngreso, delIngreso, getIngresos} from '../../services';
import {IIngreso, IIngresoFilter, IIngresoRes, IIngresoState} from "../types/IIngreso";
import historyHelper from "../../helpers/history";
import {AppState} from "./rootReducer";

const initialState: IIngresoState = {
    status: "idle"
}

export const mostrarIngresos = createAsyncThunk(
    'mostrarIngresos', async (params:IIngresoFilter|undefined, thunkAPI ) => {
        const {ingresos} = thunkAPI.getState() as AppState;
        let paramsFilter = params || ingresos.filtered;

        const response = await getIngresos(paramsFilter)
        return {data: response.data as IIngresoRes, params: response.config.params}
    }
)

export const agregarEditarIngreso = createAsyncThunk(
    'Ingresos/agregarEditar', async (ingreso: IIngreso) => {
        const response = await addEditIngreso(ingreso);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return {data: response.data, edit:!!ingreso.id};
    }
);

export const eliminarIngreso = createAsyncThunk(
    'ingreso/eliminar', async (id: number) => {
        const response = await delIngreso(id);
        if (response.status === 200 || response.status === 204) historyHelper.replace("ingresos");
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarIngresos',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarIngresos
        builder.addCase(mostrarIngresos.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarIngresos.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarIngresos.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarIngreso
        builder.addCase(agregarEditarIngreso.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarIngreso.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.items = state.list?.items.filter(e => e.id !== action.payload.data.id);
                state.list.items.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalCount
                }

            }
        })
        builder.addCase(agregarEditarIngreso.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarIngreso
        builder.addCase(eliminarIngreso.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarIngreso.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.items){
                state.list.items = state.list?.items.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarIngreso.rejected, (state ) => {
            state.status = "idle";
        })
    }
});

export default slice.reducer;
