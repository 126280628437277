import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getIndicadoresMenu, getResumenGrafico, getWidgets, getWidgetsGasIng} from '../../services';
import {IAppState} from "../types/Icommon";
import {mostrarCxC} from './prestamosReducer';
import {AppState} from "./rootReducer";


export interface IDashBoardState extends IAppState{
    rutaId?:number,
    ingresos:number,
    maxPrestamos:number,
    prestamos:number,
    clientes:number,
    clientesActivos:number,
    capitalActual:number,
    interes:number,
    egresos:number,
    ingresosChart: {
        name: string,
        data: number[]
    }[],
    solicitudes:number,
    notificaciones:number,
}


const initialState:IDashBoardState =   {
    status: "idle",
    ingresos:0,
    maxPrestamos:0,
    prestamos:0,
    clientes:0,
    clientesActivos:0,
    capitalActual:0,
    interes:0,
    egresos:0,
    ingresosChart:[],
    solicitudes:0,
    notificaciones:0,
    filtered:{},
}



export const mostrarDashBoardData = createAsyncThunk(
    'mostrarDashBoardData', async (_,thunkAPI) => {

        const {rutaId} = (thunkAPI.getState() as AppState)?.dashBoard;

        const widgets = await getWidgets(rutaId);
        const ingresos = await getResumenGrafico(undefined,rutaId);

        thunkAPI.dispatch(mostrarCxC(false))
        thunkAPI.dispatch(mostrarWidgetsGasIng())


        return {widgets: widgets.data, ingresosChart:ingresos.data} as any
    }
)

export const mostrarWidgetsGasIng = createAsyncThunk(
    'mostrarWidgetsGasIng', async (ciclo:number|undefined,thunkAPI) => {
        const {rutaId} = (thunkAPI.getState() as AppState)?.dashBoard;

        const response = await getWidgetsGasIng(ciclo,rutaId)

        return response.data as {egresos:number, ingresos:number}
    }
)

export const setDashBoardFilter = createAsyncThunk(
    'setDashBoardFilter', async (filter, thunkAPI) => {

        // let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        // if(params.estado && params.estado === "incluirCxcAldia"){
        //     thunkAPI.dispatch(mostrarCxC({incluirCxcAldia:true}))
        // }
        //
        //
         return {cxc:filter} as any
    }
)

export const mostrarIndicadoresMenu = createAsyncThunk(
    'mostrarIndicadoresMenu', async () => {
        const response = await getIndicadoresMenu()

        return response.data as { solicitudes: number, notificaciones: number }
    }
)


const slice = createSlice({
    name: 'mostrarCompanias',
    initialState,
    reducers: {
        setDashBoardRutaId(state,action: PayloadAction<number|undefined>) {
            state.rutaId = action.payload;
        },
        setIndicadoresMenu(state,action: PayloadAction<{solicitudes?: number, notificaciones?:number}>) {
            state.solicitudes += action.payload.solicitudes ?? 0;
            state.notificaciones += action.payload.notificaciones ?? 0;
        },
        setDashBoardState(state,action: PayloadAction<{solicitudes: number, notificaciones: number  }>) {
            const {solicitudes, notificaciones} = action.payload
            state.solicitudes = state.solicitudes + solicitudes;
            state.notificaciones = state.notificaciones + notificaciones;
        },
    },
    extraReducers: builder => {
        //---mostrarCompanias
        builder.addCase(mostrarDashBoardData.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarDashBoardData.fulfilled, (state,action ) => {
            state.status = "idle";
            const { widgets, ingresosChart} = action.payload

                state.maxPrestamos = widgets.maxPrestamos;
                state.prestamos= widgets.prestamos;
                state.clientes= widgets.clientes;
                state.clientesActivos= widgets.clientesActivos;
                state.capitalActual= widgets.capitalActual;
                state.interes=  widgets.interes;


                state.ingresosChart = ingresosChart;


        })
        builder.addCase(mostrarDashBoardData.rejected, (state ) => {
            state.status = "idle";
        })


        //---mostrarWidgetsGasIng
        builder.addCase(mostrarWidgetsGasIng.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarWidgetsGasIng.fulfilled, (state,action ) => {
            state.status = "idle";
            const { egresos, ingresos } = action.payload
            state.egresos = egresos;
            state.ingresos = ingresos;
        })
        builder.addCase(mostrarWidgetsGasIng.rejected, (state ) => {
            state.status = "idle";
        })
        //setDashBoardFilter
        builder.addCase(setDashBoardFilter.fulfilled, (state,action ) => {
            state.filtered = {...state.filtered,...action.payload};
        })
        ///mostrarIndicadoresMenu
        builder.addCase(mostrarIndicadoresMenu.fulfilled, (state,action ) => {
            const {solicitudes, notificaciones} = action.payload
            state.solicitudes = solicitudes;
            state.notificaciones = notificaciones;
        })
    }

});

export const {setDashBoardRutaId, setDashBoardState, setIndicadoresMenu} = slice.actions;

export default slice.reducer;
