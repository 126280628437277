import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getRutas, addEditRuta, delRuta, inactivateRuta} from '../../services';
import historyHelper from "../../helpers/history";
import {IRuta, IRutaState} from "../types/IRuta";

const initialState: IRutaState = {
    status: "idle",
    list:[]
}

export const mostrarRutas = createAsyncThunk(
    'mostrarRutas', async (filter:any = {}) => {

        const response = await getRutas(filter)
        return {data:response.data as IRuta[], params: response.config.params}
    }
)
export const agregarEditarRuta = createAsyncThunk(
    'rutas/agregarEditar', async (ruta: IRuta) => {
        const response = await addEditRuta(ruta);

        if (response.status === 200) {
            historyHelper.back();
        }
        return {data: response.data, edit:!!ruta.id};
    }
);
export const eliminarRuta = createAsyncThunk(
    'rutas/eliminar', async (id: number) => {
        await delRuta(id);
        return id;
    }
);
export const inactivarRuta = createAsyncThunk(
    'ruta/inactivar', async (id: number) => {
        const response = await inactivateRuta(id);
        if (response.status === 200 || response.status === 204) {
            historyHelper.replace("/rutas")
        }
        return id;
    }
);
const slice = createSlice({
    name: 'mostrarRutas',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarRutas
        builder.addCase(mostrarRutas.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarRutas.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarRutas.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarRuta
        builder.addCase(agregarEditarRuta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarRuta.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list = state.list?.filter(e => e.id !== action.payload.data.id);
                state.list.unshift(action.payload.data);
            }
        })
        builder.addCase(agregarEditarRuta.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarRuta
        builder.addCase(eliminarRuta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarRuta.fulfilled, (state,action ) => {
            state.status = "idle";

            const id = action.payload;
            if(state.list){
                state.list = state.list?.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarRuta.rejected, (state ) => {
            state.status = "idle";
        })
        // inactivarRuta
        builder.addCase(inactivarRuta.fulfilled, (state,action ) => {
            state.status = "idle";

            const id  = action.payload;
            if(state.list){
                state.list = state.list?.map(item => {
                    if(item.id === id){
                        return {...item, inactivo: !item.inactivo}
                    }
                    return item
                });
            }
        })


    }

});


export default slice.reducer;
