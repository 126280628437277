import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getFeriados,addEditFeriado,delFeriado} from '../../services';
import historyHelper from "../../helpers/history";
import {IFeriado, IFeriadoState} from "../types/IFeriado";

const initialState: IFeriadoState = {
    status: "idle",
    list:[]
}

export const mostrarFeriados = createAsyncThunk(
    'mostrarFeriados', async (name?:string|undefined ) => {
        const response = await getFeriados(name)
        return {data:response.data as IFeriado[], params: response.config.params}
    }
)
export const agregarEditarFeriado = createAsyncThunk(
    'feriados/agregarEditar', async (feriado: IFeriado) => {
        const response = await addEditFeriado(feriado);

        if (response.status === 200) {
            historyHelper.back();
        }
        return {data: response.data, edit:!!feriado.id};
    }
);
export const eliminarFeriado = createAsyncThunk(
    'feriados/eliminar', async (id: number) => {
        await delFeriado(id);
        return id;
    }
);
const slice = createSlice({
    name: 'mostrarFeriados',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarFeriados
        builder.addCase(mostrarFeriados.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarFeriados.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarFeriados.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarFeriado
        builder.addCase(agregarEditarFeriado.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarFeriado.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list = state.list?.filter(e => e.id !== action.payload.data.id);
                state.list.unshift(action.payload.data);
            }
        })
        builder.addCase(agregarEditarFeriado.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarFeriado
        builder.addCase(eliminarFeriado.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarFeriado.fulfilled, (state,action ) => {
            state.status = "idle";

            const id = action.payload;
            if(state.list){
                state.list = state.list?.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarFeriado.rejected, (state ) => {
            state.status = "idle";
        })
    }

});


export default slice.reducer;
