import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addEditPlantilla, delPlantilla, getPlantillas} from '../../services';
import {IPlantilla, IPlantillaState} from "../types/IPlantilla";
import {AppState} from "./rootReducer";

const initialState: IPlantillaState= {
    status: "idle",
    list:[]
}

export const mostrarPlantillas = createAsyncThunk(
    'mostrarPlantillas', async (filter:any = {}) => {
        const response = await getPlantillas(filter)
        return {data: response.data as IPlantilla[]}
    }
)

export const mostrarPlantillaById = createAsyncThunk(
    'mostrarPlantillaById', async (id:number,thunkAPI) => {
        let byId = (thunkAPI.getState() as AppState)?.plantillas?.list?.find(c=>c.id === id);
        if(byId){
            return {data: byId}
        }

        const response = await getPlantillas(id);
        return {data: response.data as IPlantilla}
    }
)
export const mostrarPlantillasSinoEsta = createAsyncThunk(
    'mostrarPlantillasSinoEsta', async (id:number|undefined,thunkAPI) => {
        let plantillas = (thunkAPI.getState() as AppState)?.plantillas;
        if (plantillas?.list?.length || plantillas.status === "loading") return false;

        return thunkAPI.dispatch(mostrarPlantillas(undefined));

    }
)


export const agregarEditarPlantilla = createAsyncThunk(
    'Plantilla/agregarEditar', async (plantilla: IPlantilla) => {
        const response = await addEditPlantilla(plantilla);

        // if (response.status === 200 || response.status === 204) historyHelper.back();

        return {data: response.data, edit:!!plantilla.id};

    }
);
export const eliminarPlantilla = createAsyncThunk(
    'plantilla/eliminar', async (id: number) => {
        await delPlantilla(id);
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarPlantillas',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        //--mostrarPlantillas
        builder.addCase(mostrarPlantillas.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPlantillas.fulfilled, (state,action ) => {
            state.status = "idle";
            state.list = action.payload.data;
        })
        builder.addCase(mostrarPlantillas.rejected, (state ) => {
            state.status = "idle";
        })
        //--mostrarPlantillaById
        builder.addCase(mostrarPlantillaById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPlantillaById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data;
        })
        builder.addCase(mostrarPlantillaById.rejected, (state ) => {
            state.status = "idle";
        })
        //--agregarEditarPlantilla
        builder.addCase(agregarEditarPlantilla.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarPlantilla.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data;

            if(state.list){
                state.list = state.list?.filter(e => e.id !== action.payload.data.id);
                state.list.unshift(action.payload.data);
                // if(!action.payload.edit){
                //     ++state.list.pageSize
                //     ++state.list.totalRecordCount
                // }
            }
        })
        builder.addCase(agregarEditarPlantilla.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarPlantilla
        builder.addCase(eliminarPlantilla.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarPlantilla.fulfilled, (state,action ) => {
            state.status = "idle";

            const id = action.payload;
            if(state.list){
                state.list = state.list?.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarPlantilla.rejected, (state ) => {
            state.status = "idle";
        })
    }

});




export default slice.reducer;
