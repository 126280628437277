import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { ITheme } from '../types/ITheme';

const initialState: ITheme = {
    drawerOpen: false,
    viewTableList: false,
    paypalPlanOpen:false,
    installBannerEvent:null,
    whatsappBot: false,
    openWsBotModal: false,
    notifSideOpened: false,

}
const slice = createSlice({
    name: 'themeSetting',
    initialState,
    reducers: {
        toggleDrawer(state) {
            state.drawerOpen = !state.drawerOpen;
        },
        toggleNotifSide(state, action: PayloadAction<boolean|undefined>) {
            if (action.payload !== undefined){
                state.notifSideOpened = action.payload;
            }else{
                state.notifSideOpened = !state.notifSideOpened;
            }
        },
        setWhatsappBot(state, action: PayloadAction<{ ready:boolean }>) {
            state.whatsappBot = action.payload.ready;
        },
        setOpenWsBotModal(state, action: PayloadAction<{ open:boolean }>) {
            state.openWsBotModal = action.payload.open;
        },
        togglePaypalPlan(state) {
            state.paypalPlanOpen = !state.paypalPlanOpen;
        },
        toggleViewTable(state: ITheme) {
            state.viewTableList = !state.viewTableList;
        },
        setInstallBannerEvent(state: ITheme,action: PayloadAction<any>) {
            state.installBannerEvent = action.payload;
        }
    },

});

export const {toggleDrawer,toggleNotifSide, togglePaypalPlan,toggleViewTable,setWhatsappBot,setOpenWsBotModal,setInstallBannerEvent} = slice.actions;
export default slice.reducer;
