import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {getMonedas} from '../../services';
import {AppState} from "./rootReducer";
import {IMoneda, IMonedasState} from "../types/IMoneda";

const initialState: IMonedasState= {
    status: "idle",
    list:[]
}

export const mostrarMonedas = createAsyncThunk(
    'mostrarMonedas', async () => {
            const response = await getMonedas()
            return {data: response.data as IMoneda[]}
    }
)
export const mostrarMonedasSinoEsta = createAsyncThunk(
    'mostrarMonedasSinoEsta', async (_,thunkAPI) => {
        let monedas = (thunkAPI.getState() as AppState)?.monedas;
        if (monedas?.list?.length || monedas.status === "loading") return false;

        return thunkAPI.dispatch(mostrarMonedas());

    }
)

const slice = createSlice({
    name: 'mostrarMonedas',
    initialState,
    reducers: {
        setMonedas(state,action: PayloadAction<IMoneda[]>) {
            state.list = action.payload;
        },
    },
    extraReducers: builder => {
        //--mostrarMonedas
        builder.addCase(mostrarMonedas.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarMonedas.fulfilled, (state,action ) => {
            state.status = "idle";
            state.list = action.payload.data;
        })
        builder.addCase(mostrarMonedas.rejected, (state ) => {
            state.status = "idle";
        })
    }

});



export const {setMonedas} = slice.actions;

export default slice.reducer;
