import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getZonasHorarias} from '../../services';
import {IAppState, IKeyValue} from "../types/Icommon";

interface IState extends IAppState{
    list: IKeyValue[]
}
const initialState: IState = {
    status: "idle",
    list: []
}

export const mostrarZonasHorarias = createAsyncThunk(
    'mostrarZonasHorarias', async () => {
        const response = await getZonasHorarias()
        return {data: response.data as IKeyValue[], params: response.config.params}
    }
)


const slice = createSlice({
    name: 'mostrarZonasHorarias',
    initialState,
    reducers: { },
    extraReducers: builder => {
        //---mostrarZonasHorarias
        builder.addCase(mostrarZonasHorarias.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarZonasHorarias.fulfilled, (state,action ) => {
            state.status = "idle";
            const { data } = action.payload
            state.list = data;
        })
        builder.addCase(mostrarZonasHorarias.rejected, (state ) => {
            state.status = "idle";
        })

    }

});


export default slice.reducer;
