import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addEditGasto, delGasto, getGastos} from '../../services';
import {IGasto, IGastoFilter, IGastoRes, IGastoState} from "../types/IGasto";
import historyHelper from "../../helpers/history";
import {AppState} from "./rootReducer";

const initialState: IGastoState = {
    status: "idle"
}

export const mostrarGastos = createAsyncThunk(
    'mostrarGastos', async (params:IGastoFilter|undefined, thunkAPI ) => {
        const {gastos} = thunkAPI.getState() as AppState;
        let paramsFilter = params || gastos.filtered;

        const response = await getGastos(paramsFilter)
        return {data: response.data as IGastoRes, params: response.config.params}
    }
)

export const agregarEditarGasto = createAsyncThunk(
    'Gastos/agregarEditar', async (gasto: IGasto) => {
        const response = await addEditGasto(gasto);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return {data: response.data, edit:!!gasto.id};
    }
);

export const eliminarGasto = createAsyncThunk(
    'gasto/eliminar', async (id: number) => {
        const response = await delGasto(id);
        if (response.status === 200 || response.status === 204) historyHelper.replace("gastos");
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarGastos',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarGastos
        builder.addCase(mostrarGastos.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarGastos.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarGastos.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarGasto
        builder.addCase(agregarEditarGasto.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarGasto.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.items = state.list?.items.filter(e => e.id !== action.payload.data.id);
                state.list.items.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalCount
                }

            }
        })
        builder.addCase(agregarEditarGasto.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarGasto
        builder.addCase(eliminarGasto.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarGasto.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.items){
                state.list.items = state.list?.items.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarGasto.rejected, (state ) => {
            state.status = "idle";
        })
    }
});

export default slice.reducer;
